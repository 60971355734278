<template>
    <div class="field field-selection-buttons" :class="{
        'is-invalid': !!errorMessage,
        'is-disabled': disabled
    }">
        <slot name="label">
            <p v-if="label" class="form-label">{{ label }}<span v-if="mandatory" class="text-danger ms-1">*</span></p>
        </slot>
        <div v-if="multiple" class="hstack align-center mt-4 mb-3">
            <Field v-slot="{ field }" name="toggleAllConditions" type="checkbox" :value="false">
                <label class="hstack justify-content-center align-items-center">
                    <input
                        @click="selectAllSkinConditions($event)"
                        type="checkbox"
                        v-bind="field"
                        :value="false"
                        class="form-check-input mt-0 me-2" />
                    {{ selectMultipleCtaText }}
                </label>
            </Field>
        </div>
        <div class="selection-button-list">
            <SelectionButtonItem v-for="item in items" :active="isItemSelected(item)" @click="onClick(item)">
                <slot name="selection-item" :item="item">
                    {{ getItemText(item) }}
                </slot>
            </SelectionButtonItem>
        </div>
        <p v-if="hint" class="hint-message caption text-muted mt-1">{{ hint }}</p>
        <p v-if="errorMessage" class="error-message caption text-danger">{{ errorMessage }}</p>
    </div>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue';
import SelectionButtonItem from './selection-button-item.vue';
import commonProps from './props';
import { Field } from 'vee-validate';

const props = defineProps({
    modelValue: [Array, String, Number] as PropType<Array<string> | string | number>,
    errorMessage: String,
    ...commonProps,
})

const emit = defineEmits(['update:modelValue'])

const getInitialValue = () => {
    if (!props.modelValue) return [];
    if (Array.isArray(props.modelValue)) return props.modelValue;
    return [props.modelValue];
}

const internalValue = ref<Array<string|number>>(getInitialValue());

const getItemValue = (item: any) => {
    if (!item) return '';
    if (typeof item === 'string') return item;
    return item[props.itemValue as keyof typeof item]
}

const getItemText = (item: any) => {
    if (!item) return '';
    if (typeof item === 'string') return item;
    if (!props.itemText) return;
    return item[props.itemText as keyof typeof item];
}

const isItemSelected = (item: any) => {
    if (!props.multiple) {
        return getItemValue(item) === props.modelValue;
    } else {
        return Array.isArray(props.modelValue) && props.modelValue.includes(getItemValue(item));
    }
}

const onClick = (item: any) => {
    if (props.disabled) return;
    const value = getItemValue(item);
    if (typeof item === 'string' && !item.trim()) {
        return;
    }
    if (!props.multiple) {
        internalValue.value[0] = value;
        return emit('update:modelValue', value);
    }

    const modelValue = props.modelValue;
    let newArray = Array.isArray(modelValue) ? modelValue : [];
    if (!isItemSelected(item)) {
        newArray.push(value);
    } else {
        const itemValueIndex = newArray.findIndex(x => x === value);
        if (itemValueIndex !== -1) {
            newArray.splice(itemValueIndex, 1);
        }
    }
    newArray = newArray.filter(
        (value, index, array) => array.indexOf(value) === index
    )
    internalValue.value = newArray;
    emit('update:modelValue', newArray)
}

const selectAllSkinConditions = (e: Event) => {
    const target = e.target as HTMLInputElement;
    if (target.checked) {
        for (const item in props.items) {
            if (!isItemSelected(props.items[item])) {
                onClick(props.items[item])
            }
        }
    } else {
        for (const item in props.items) {
            if (isItemSelected(props.items[item])) {
                onClick(props.items[item])
            }
        }
    }
}

</script>

<style lang="scss">
.field-selection-buttons {
    &.slim-btn {
        .selection-button-item {
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
            .selection-emblem-active {
                .icon {
                    width: 0.75rem;
                    height: 0.75rem;
                }
            }
        }
    }
    .selection-emblem-active {
        visibility: hidden;
    }

    .selection-button-list {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(v-bind(columnCount), 1fr);
    }

    &.is-invalid {
        .selection-button-item {
            border-color: $danger;
            color: $danger;
        }
    }

    &.is-disabled {
        .selection-button-item {
            cursor: not-allowed;
            opacity: 50%;
        }
    }

    @include media-breakpoint-up (lg) {
        .selection-button-list {
            grid-template-columns: repeat(v-bind(columnCountMd), 1fr);
        }
    }
}
</style>