<template>
    <SelectionButtons
        v-bind="$props"
        :id="idAttr"
        :name="name"
        :label="label"
        :model-value="value"
        @update:model-value="onHandleChange($event)"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        :multiple="multiple"
        :error-message="errorMessage"
        :mandatory="mandatory"
        :disabled="disabled">
        <template #label>
            <slot name="label" :id="idAttr"></slot>
        </template>
        <template #selection-item="scope"><slot name="selection-item" v-bind="scope"></slot></template>
    </SelectionButtons>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate';
import SelectionButtons from './selection-buttons.vue';
import commonProps from './props';
import { computed, toRef } from 'vue';
import kebabCase from 'lodash/kebabCase';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    id: String,
    name: { type: String, required: true },
    ...commonProps
})

const fieldName = toRef(props, 'name');

const idAttr = computed(() => props.id ?? 'selectionButtons-' + kebabCase(props.name));

const {
    handleChange,
    errorMessage,
    value
} = useField<string|number|Array<string>>(fieldName, undefined, {
    initialValue: props.multiple ? [] : undefined
})

const onHandleChange = (e: unknown) => {
    emit('update:modelValue', value);
    handleChange(e)
}
</script>