import { PropType } from "vue";

export default {
    label: String,
    items: {
        type: Array as PropType<any[]>,
        default: () => []
    },
    itemText: String,
    itemValue: String,
    multiple: Boolean,
    hint: String,
    mandatory: Boolean,
    disabled: Boolean,
    columnCount: {
        type: Number,
        default: 1,
    },
    columnCountMd: {
        type: Number,
        default: 3,
    },
    selectMultipleCtaText: {
        type: String,
        default: 'Select all zones'
    }
}